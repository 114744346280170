import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  Observable,
  ServerError,
  setLogVerbosity,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { print } from 'graphql/language/printer';
import { authProviderInstance } from '../providers/AuthProvider';
import StorageService from '../services/StorageService';
import { baseFromUrl } from './GraphqlHelper';

const apiUrl = process.env.REACT_APP_API_ENDPOINT || `http://localhost:4041/`;
const domain = baseFromUrl(apiUrl);

const fetchRefreshToken = async () => {
  const url = new URL(domain + '/oauth/token');

  const refreshToken = StorageService.getItem('refreshToken');
  console.log('refreshToken', refreshToken);
  if (!refreshToken) {
    console.error('Refresh token is not provided');
    authProviderInstance.logout();
    return;
  }

  url.searchParams.append('token', refreshToken);
  url.searchParams.append('grant_type', 'refresh_token');

  let refreshResult;
  try {
    console.log('fetching from', url);
    refreshResult = await fetch(url + '').then(r => r.json());
  } catch (e) {
    console.error('Cannot obtain a new token', e);
    authProviderInstance.logout();
    return;
  }

  return refreshResult;
};

const promiseToObservable = (promise: any) =>
  new Observable((subscriber: any) => {
    promise.then(
      (value: any) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err: any) => subscriber.error(err),
    );
    return subscriber; // this line can removed, as per next comment
  });

const httpLink = createUploadLink({ uri : apiUrl });

const loggingRequestLink = new ApolloLink((operation, forward) => {
  console.log('%c Request Payload ', 'background: rgba(74,144,226, .3);', {
    variables : operation.variables,
    query     : print(operation.query),
  });

  operation.setContext(({ headers = {} }: any) => {
    const token = StorageService.getItem('accessToken');
    if (token) {
      return {
        headers : {
          ...headers,
          Authorization : token ? `Bearer ${token}` : null,
        },
      };
    }
  });

  return forward(operation);
});

const loggingResponseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    console.log('%c Response Payload ', 'background: rgba(74,226, 144, .3);', response.data || '');
    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  const refreshTokenFn = () => promiseToObservable(fetchRefreshToken()).flatMap((data: any) => {
    console.log('response', data);
    if (data) {
      StorageService.setItem('accessToken', data.accessToken);
      operation.setContext(({ headers = {} }: any) => ({
        headers : {
          ...headers,
          Authorization : `Bearer ${data.accessToken}`,
        },
      }));
    }

    return forward(operation);
  });

  if (graphQLErrors) {
    if (graphQLErrors[0].message.includes('jwt expired')) {
      console.log('JWT EXPIRED');
      return refreshTokenFn();
    }

    return;
  }

  if (networkError && (networkError as ServerError).statusCode === 401) {
    const serverError = (networkError as ServerError);
    if (serverError.result?.error?.includes('jwt expired')) {
      console.log('NETWORK: JWT EXPIRED');
      return refreshTokenFn();
    } else {
      console.log(`[Network error]: ${serverError.result}`);
    }
  }
});

const graphqlClient = new ApolloClient({
  link  : from([loggingRequestLink, loggingResponseLink, errorLink, httpLink]),
  cache : new InMemoryCache({
    possibleTypes: {
      LoginResult: ['AuthData', 'TwoFactorRequest', 'TwoFactorOptions']
    }
  }),
});

export {
  graphqlClient,
};
