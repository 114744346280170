import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Formatter } from '../../helpers/Formatter';
import { usePromise } from '../../hooks/usePromise';
import { useHotSymbol } from '../../providers/HotSymbolContext';
import { optionService } from '../../services/Entity/OptionService';
import { positionService } from '../../services/Entity/PositionService';
import css from './StrategyOptimizer.module.css';

export function StrategyOptimizer() {
  const { register, handleSubmit, setValue, formState : { errors } } = useForm();
  const [data, setData]: any = useState();
  const [loading, setLoading]: any = useState();
  const [error, setError]: any = useState();
  const [lastKnownSymbol, setLastKnownSymbol] = useState('');
  const [isSuggestOpen, setSuggestOpen] = useState(false);
  const defaultFormValue = {
    symbol : ' ',
    strike : 0,
  }

  async function onSubmit(data: any) {
    setLoading(true);
    try {
      const res = await optionService.fetchOptionItems(data);
      setData(res);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(err.toString());
    }
  }

  const { symbol } = useHotSymbol();
  if (lastKnownSymbol !== symbol && symbol) {
    setLastKnownSymbol(symbol);
    setValue('symbol', symbol);
    // handleSubmit(onSubmit)();
  }

  function onSymbolFocus() {
    // setSuggestOpen(true);
  }

  return (
    <div>
      <h3 style={{ width : 400 }}>Strategy Optimizer</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          Symbol:
          <div style={{ display : 'inline-flex', position: 'relative' }}>
            <input type="text"
                   className={css.symbolInput}
                   {...register('symbol')}
                   defaultValue={defaultFormValue.symbol}
                   onFocus={onSymbolFocus}
            />
            {isSuggestOpen && <div style={{ position: 'absolute', top: 25, left: 0, background: '#f0f0f0', width: '100%' }}>
              <div>AMC</div>
              <div>TSLA</div>
            </div>}
          </div>
          <select className={css.strategyInput} {...register('strategy')}>
            <option value="call">Single Call</option>
            <option value="putt">Single Put</option>
            <option value="pcs">Put Credit Spread</option>
          </select>
        </div>
        <div style={{ display : 'flex' }}>
          <div>
            Strike: <input type="number" className={css.symbolInput} {...register('strike')}
                           defaultValue={defaultFormValue.strike}/>
          </div>
          <button>Go</button>
        </div>
      </form>
      {loading && 'Loading...'}
      {!!error && error}
      {data?.price &&
      <div style={{ marginTop : 20 }}>
        Last Price: {data?.price}
        <table>
          <thead>
          <tr>
            <td style={{ width : 60 }}>Date</td>
            <td style={{ width : 40 }}>DTE</td>
            <td style={{ width : 70 }}>Strike</td>
            <td style={{ width : 60 }}>Bid</td>
            <td style={{ width : 60 }}>Ask</td>
            <td style={{ width : 50 }}>ViC</td>
            <td style={{ width : 50 }}>IV</td>
            <td style={{ width : 50 }}>OI</td>
            <td>T</td>
            <td>D</td>
          </tr>
          </thead>
          <tbody>
          {data?.options?.map((option: any) => {
            const date = DateTime.fromSQL(option.date);
            const dte = date.diffNow('days').days.toFixed(0);
            const ratingClass = `rating${option.rating}`;

            return <tr key={option.date} className={css[ratingClass]}>
              <td style={{ position : 'relative' }}>
                {date.toFormat('LL/dd')}{date.year === new Date().getFullYear() ? '' : `(${date.year})`}
                {option.rating === 11 && <span className={css.star10}>&#9733;</span>}
              </td>
              <td>{dte}</td>
              <td>{option.strike.toFixed(2)}</td>
              <td>{Formatter.amount(option.bid)}</td>
              <td>{Formatter.amount(option.ask)}</td>
              <td>
                {option.vic.toFixed(0)}%
              </td>
              <td>{option.iv.toFixed(0)}%</td>
              <td>{option.openInterest}</td>
              <td>{option.theta.toFixed(2)}</td>
              <td>{option.delta.toFixed(2)}</td>
            </tr>;
          })}
          </tbody>
        </table>
      </div>
      }
    </div>
  );
}
