import { gql } from '@apollo/client';

const fragments: any = {};

fragments.user = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
  }
`;

fragments.position = gql`
  fragment PositionFragment on Position {
    id
    symbol
    type
    size
    entryPrice
    currentPrice
    createdAt
    openedAt
  }
`;

fragments.watchlist = gql`
  fragment WatchlistFragment on Watchlist {
    id
    name
    isDefault
    items {
      id
      
    }
  }
`

fragments.loginResult = gql`
  fragment LoginResultFragment on LoginResult {
    ... on AuthData {
      accessToken
      refreshToken
      user {
        id
        email
        firstName
        lastName
      }
    }
    ... on TwoFactorRequest {
      identifier
      type
      hash
    }
  }
`;

export { fragments };
