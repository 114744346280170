import { gql } from '@apollo/client';
import { fragments } from './fragments';
import { createCUDMutations } from './GraphqlHelper';

let mutations: any = {
  loginWithEmail : gql`
    mutation loginWithEmail($data: InputLoginWithEmail!) {
      loginWithEmail(data: $data) {
        ...LoginResultFragment
      }
    }
    ${fragments.loginResult}
  `,
  signupWithEmail : gql`
    mutation signupWithEmail($data: InputUser) {
      signupWithEmail(data: $data) {
        ...LoginResultFragment
      }
    }
    ${fragments.loginResult}
  `,
  process2FA : gql`
    mutation process2FA($hash: String!, $code: String!) {
      process2FA(hash: $hash, code: $code) {
        ... on AuthData {
          accessToken
          refreshToken
          user {
            firstName
            lastName
            email
          }
        }
      }
    }
  `,

  uploadImage : gql`
    mutation uploadImage($file: Upload!) {
      uploadImage(file: $file) {
        fileId
        url
        file {
          id
          name
          size
        }
      }
    }
  `,
  updateMyProfile       : gql`
    mutation updateMyProfile($data: InputUser!) {
      updateMyProfile(data: $data) {
        id
      }
    }
  `,
  updateSettings : gql`
    mutation updateSettings($data: InputSettings!) {
      updateSettings(data: $data) {
        notifications {
          orders
        }
      }
    }
  `,
};

mutations = {
  ...mutations,
  ...createCUDMutations('User'),
  ...createCUDMutations('Position'),
};

export { mutations };
