import { graphqlClient } from '../../graphql/GraphqlClient';
import { MutationOptions, QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { DocumentNode } from '@apollo/client';

class GraphqlService {
  getOperationName = (operation: DocumentNode): string => {
    // @todo type checking
    // @ts-ignore
    return operation.definitions.find((item: any) => item.kind === 'OperationDefinition' && item.name.kind === 'Name').name.value;
  };

  mutate = <Result, Variables>(options: MutationOptions<Result, Variables>): Promise<Result> => {
    return graphqlClient.mutate<Result, Variables>(options).then(r => {
      // @ts-ignore
      return r.data[this.getOperationName(options.mutation)];
    });
  };

  query = <Result, Variables>(options: QueryOptions<Variables>): Promise<Result> => {
    return graphqlClient.query<Result, Variables>(options).then(r => {
      // @ts-ignore
      return r.data[this.getOperationName(options.query)];
    });
  };
}

export const graphqlService = new GraphqlService();
