import React from 'react';
import { watchlistService } from '../../services/Entity/WatchlistService';

export function Watchlist(props: any) {
  // wss://ws.finnhub.io
  const watchLists = watchlistService.getAll({});
  console.log(watchLists);
  return (
    <div>
      <h3>Watchlist</h3>
    </div>
  )
}
