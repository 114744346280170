import { gql } from '@apollo/client';
import { createListQuery, createModelQuery } from './GraphqlHelper';

const queries = {
  me          : gql`
    query me {
      me {
        id
        firstName
        lastName
        email
      }
    }
  `,
  optionItems : gql`
    query optionItems($data: InputOptionItemsParams) {
      optionItems(data: $data) {
        symbol
        price
        options {
          date
          symbol
          strike
          bid
          ask
          theta
          delta
          openInterest
          iv
          vic
          rating
        }
      }
    }
  `,

  user       : createModelQuery('user'),
  users      : createListQuery('user', { withFilters : true }),
  positions  : createListQuery('position', { useConnection : true }),
  watchlists : createListQuery('watchlist'),

  settings : gql`
    query settings {
      settings {
        notifications {
          orders
        }
      }
    }
  `,

};

export { queries };
