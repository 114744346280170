import { Formatter } from '../../helpers/Formatter';
import { useAuth } from '../../providers/AuthProvider';
import { Dropdown } from '../UI/Dropdown/Dropdown';
import css from './Header.module.css';

export function Header() {
  const auth = useAuth();
  const name = Formatter.fullName(auth.user);
  return (
    <div className={css.header}>
      <span>tradeassist.io</span>
      <Dropdown align="right" label={<span className={css.username}>Hello, {name} &#9660;</span>}>
        <Dropdown.Item onClick={auth.logout}>logout</Dropdown.Item>
      </Dropdown>
    </div>
  );
}
