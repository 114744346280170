import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon/Icon';
import css from './Button.module.css';

interface Props {
  onClick?: any
  link?: string
  className?: string
  type?: 'button' | 'reset' | 'submit'
  icon?: string
  title?: string
  style?: CSSProperties
  disabled?: boolean
}

class Button extends React.Component<Props> {

  render() {
    const { className, link, disabled, icon, ...props } = this.props;
    const elementProps: any = {
      type: 'button',
      className: css.button,
      disabled,
    };

    let Component: any = 'button';
    if (link) {
      if (link.startsWith('http')) {
        Component = 'a';
        elementProps.href = link;
        elementProps.target = '_blank';
      } else {
        Component = Link;
        elementProps.to = link;
      }
    }

    return <Component {...elementProps} {...props}>
      {icon ? <Icon name={icon} size={16}/> : null}
      {props.children ? <span>{props.children}</span> : null}
    </Component>;
  }
}

export default Button;
