import * as React from 'react';
import { useDebouncedEffect } from './useDebouncedEffect';

export function usePromise<Args, Data>(promise: (args: Args) => Promise<Data>, args: Args, debounce = 0, skip = false) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Data | null | undefined>(undefined);
  const [error, setError] = React.useState<any>(null);

  const fetchData = async () => {
    if (skip) return null;

    try {
      setLoading(true);
      setError(false);
      const res = await promise(args);
      setData(res);
    } catch (e) {
      setError(e);
      setData(null);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (debounce) {
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useDebouncedEffect(fetchData, debounce, [JSON.stringify(args || {})]);
  } else {
    //eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      fetchData();
    }, [JSON.stringify(args || {})]);
  }

  // @todo simplify loading
  return { data, loading: loading || (data === undefined && !skip), error, setData, fetchData };
}
