import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { userService } from '../../services/Entity/UserService';
import styles from './Login.module.css';

export function Login() {
  const { register, handleSubmit, formState : { errors } } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuth();

  async function onValidForm(data: any) {
    setLoading(true);
    try {
      const loginResult = await userService.loginWithEmail({
        email    : data.email,
        password : data.password,
      })
      auth.login(loginResult);
    } catch (err: any) {
      setError(err.toString());
      console.error('couldn\'t login', err);
    }
    setLoading(false);
  }

  function onErrorForm(data: any) {
    console.log('form error', data);
  }

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h2>Login</h2>
        <form className={styles.form} onSubmit={handleSubmit(onValidForm, onErrorForm)}>
          <div>
            <input {...register('email', { required : true })} placeholder="Email"/>
            <input {...register('password')} type={'password'} placeholder="Password"/>
            {errors.email && <div className={styles.error}>Please, enter your credentials.</div>}
            {error && <div className={styles.error}>{error}</div>}
          </div>
          <button type="submit" disabled={loading}>{loading ? 'Loading...' : 'Login'}</button>
        </form>
        <hr/>
        Don't have an account? <Link to={'/signup'}>Sign Up</Link>
      </div>
    </div>
  );
}
