import { queries } from '../../graphql/queries';
import BaseEntityService from './BaseEntityService';

class OptionService extends BaseEntityService {
  fetchOptionItems = (data: any) => {
    return this.graphqlService.query({
      query     : queries.optionItems,
      variables : {
        data : {
          ...data,
          strike : parseFloat(data.strike),
        },
      },
    })
  }
}

export const optionService = new OptionService();
