import { humanize } from 'inflected';
import { DateTime } from 'luxon';
import React from 'react';

export const formatPhoneNumber = (phone: string): string => {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? '' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phone;
};

export const Formatter = {
  bool             : (value: any) => value ? 'Yes' : 'No',
  id6              : (id: string) => (id ? id.substr(-6) : ''),
  count            : (value: any) => (typeof value === 'number' ? `${(value + '').replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, '$1,')}` : '-'),
  amount           : (value: any) => (value && value.toFixed ? `$${value.toFixed(2)}`.replace('$-', '-$').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '-'),
  relativeDate     : (value: any) => {
    if (!value) {
      return '-'
    }
    if (typeof value !== 'number') {
      value = parseInt(value);
    }
    return DateTime.fromMillis(value).toRelative();
  },
  fullDate         : (value: any) => {
    if (!value) {
      return '-'
    }
    if (typeof value !== 'number') {
      value = parseInt(value);
    }
    return DateTime.fromMillis(value).toFormat('MMM d, yyyy h:mma');
  },
  shortDate        : (value: any) => {
    if (!value) {
      return '-'
    }
    if (typeof value !== 'number') {
      value = parseInt(value);
    }
    return DateTime.fromMillis(value).toFormat('MMM d, yyyy');
  },
  dayMonth        : (value: any) => {
    if (!value) {
      return '-'
    }
    if (typeof value !== 'number') {
      value = parseInt(value);
    }
    return DateTime.fromMillis(value).toFormat('MMM d');
  },
  cleverDate       : (value: any) => value ? DateTime.fromMillis(value).toFormat('MMM d, h:mma') : '',
  shortDateFromStr : (value: any) => {
    if (!value) {
      return '-'
    }
    return DateTime.fromISO(value).toFormat('MMM d');
  },
  initials(user: any) {
    if (!user) return '';
    let parts: string[] = [];
    if (user.name) {
      parts = user.name.split(' ');
    } else {
      parts = [user.firstName, user.lastName];
    }
    return parts.filter(Boolean).map(item => item.charAt(0)).join('');
  },
  fullName            : (value: any) => (value ? [value.firstName, value.lastName].join(' ').trim() : ''),
  phone               : (value: string | any) => (value ? formatPhoneNumber(value) : ''),
  humanize            : (value: any) => value ? humanize(value) : '-',
  size                : (value: number | any, sign = '″') => (value ? value + sign : value),
};
