import { mutations } from '../../graphql/mutations';
import { queries } from '../../graphql/queries';
import BaseEntityService from './BaseEntityService';

class PositionService extends BaseEntityService {
  createMutation = mutations.createPosition;
  listQuery = queries.positions;
}

export const positionService = new PositionService();
