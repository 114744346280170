import { Positions } from '../Positions/Positions';
import { StrategyOptimizer } from '../StrategyOptimizer/StrategyOptimizer';
import { Watchlist } from '../Watchlist/Watchlist';

export function Dashboard() {

  return (
    <div>
      <Watchlist />
      <div style={{ display : 'flex' }}>
        <Positions/>
        <StrategyOptimizer/>
      </div>
      <div>
      </div>
    </div>
  );
}
