import gql from 'graphql-tag';
import { pluralize } from 'inflected';
import { fragments } from './fragments';

export const extractError = (res: any) => {
  if (res.graphQLErrors && res.graphQLErrors.length) {
    return res.graphQLErrors[0].message;
  } else {
    return res.toString();
  }
};

export const createCUDMutations = (modelName: string, fields: string[] = ['id']) => {
  const fieldsString = fields.reduce((acc: string, fieldName: string) => acc + ` ${fieldName}`, '');

  const createMutation = `create${modelName}`;
  const updateMutation = `update${modelName}`;
  const deleteMutation = `delete${pluralize(modelName)}`;
  const inputType = `Input${modelName}`;

  return {
    [createMutation] : gql`
      mutation               ${createMutation}   ($data: ${inputType}!) {
        ${createMutation}(data: $data) {
        ${fieldsString}
      }
      }
    `,
    [updateMutation] : gql`
      mutation           ${updateMutation}       ($id: ID!, $data: ${inputType}!) {
        ${updateMutation}(id: $id, data: $data) {
        ${fieldsString}
      }
      }
    `,
    [deleteMutation] : gql`
      mutation            ${deleteMutation} ($ids: [ID!]!) {
        ${deleteMutation}(ids: $ids)
      }
    `,
  };
};

export function baseFromUrl(url: string): string | undefined {
  const urlObject = new URL(url);
  return urlObject.origin;
  // let match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
  // if (match) {
  //   const inner = match[0].match(/^[^\.]+\.(.+\..+)$/);
  //   if (inner) {
  //     return inner[1]
  //   }
  //   return match[0]
  // }
}

export function createModelQuery(modelName: string) {
  let fragmentName = modelName[0].toUpperCase() + modelName.slice(1) + 'Fragment';
  let fragment = fragments[modelName];
  if (fragments[modelName + 'Full']) {
    fragmentName = fragmentName + 'Full'
    fragment = fragments[modelName + 'Full'];
  }
  return gql`query ${modelName}($id: ID!) {
    ${modelName}(id: $id) {
    ...${fragmentName}
  }
  }
  ${fragment}`
}

type FragmentOptions = {
  name: String
  fragment: String
}

type ListQueryParams = {
  withFilters?: boolean;
  withPaging?: boolean;
  useConnection?: boolean;
  fragment?: FragmentOptions;
}

export function createListQuery(modelName: string, { withFilters, withPaging, fragment, useConnection }: ListQueryParams = {}) {
  const ucFirstLetter = modelName[0].toUpperCase() + modelName.slice(1);
  const fragmentName = fragment?.name || (ucFirstLetter + 'Fragment');
  const plural = pluralize(modelName);
  const filterDefinition = withFilters ? [`$filter: Input${ucFirstLetter}Filter`] : [];
  const filterInsert = withFilters ? [`filter: $filter`] : [];

  const pagingDefinition = withPaging ? ['$paging: PagingOptions', '$sort: SortingOptions'] : [];
  const pagingInsert = withPaging ? ['paging: $paging', 'sort: $sort'] : [];

  let queryDefinition = [...filterDefinition, ...pagingDefinition].join(',').trim();
  queryDefinition = queryDefinition.length ? `(${queryDefinition})` : '';
  let queryInsert = [...filterInsert, ...pagingInsert].join(',').trim();
  queryInsert = queryInsert.length ? `(${queryInsert})` : '';

  if (useConnection) {
    return gql`
      query ${plural}${queryDefinition} {
        ${plural}${queryInsert} {
        edges {
          node {
            ...${fragmentName}
          }
        }
        pageInfo {
          total
          pageSize
          pageCount
        }
      }
      }
      ${fragment?.fragment || fragments[modelName]}
    `
  } else {
    return gql`
      query ${plural}${queryDefinition} {
        ${plural}${queryInsert} {
        ...${fragmentName}
      }
      }
      ${fragment?.fragment || fragments[modelName]}
    `
  }
}
