import * as React from 'react';
import { userService } from '../services/Entity/UserService';
import StorageService from '../services/StorageService';
import { graphqlClient } from '../graphql/GraphqlClient';

type CurrentUser = any

type LoginArgs = {
  accessToken: string,
  refreshToken: string,
  expiresIn: number,
  user: CurrentUser
}

type AuthController = {
  login: (args: LoginArgs) => void,
  logout: () => void,
  fetchUser: () => void,
  isLoggedIn: boolean,
  user: any
}

export type WithAuthProps = {
  AuthController: AuthController
}

const AuthContext = React.createContext <AuthController>({
  login      : (_: LoginArgs) => undefined,
  logout     : () => undefined,
  fetchUser  : () => undefined,
  user       : {},
  isLoggedIn : false,
});

export let authProviderInstance: AuthProvider;

type State = {
  isLoggedIn: boolean,
  user?: any,
}

type Props = {}

export default class AuthProvider extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoggedIn : !!StorageService.getItem('accessToken'),
      user       : StorageService.getJsonItem('user'),
    };

  }

  componentDidMount() {
    if (this.state.isLoggedIn) {
      this.fetchUser();
    }
  }

  fetchUser = async () => {
    const user = await userService.fetchMe();
    this.setState({ user })
  };

  login = (loginData: LoginArgs) => {
    const { user } = loginData;

    StorageService.setItem('accessToken', loginData.accessToken);
    StorageService.setItem('refreshToken', loginData.refreshToken);
    StorageService.setItem('user', JSON.stringify(user));

    let redirect = '/';
    this.setState({ isLoggedIn : true, user }, () => {
      // @todo perform login redirect here
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const urlPart = redirect.split('/').slice(3).join('/');
    });
  };

  logout = () => {
    StorageService.removeItem('accessToken');
    StorageService.removeItem('refreshToken');
    StorageService.removeItem('user');

    this.setState({
      isLoggedIn : false,
      user       : undefined,
    }, async () => {
      setTimeout(() => {
        graphqlClient.clearStore();
      }, 1000);
    });
  };

  render() {

    authProviderInstance = this;

    return (
      <>
        <AuthContext.Provider value={{
          login      : this.login,
          logout     : this.logout,
          fetchUser  : this.fetchUser,
          user       : this.state.user,
          isLoggedIn : this.state.isLoggedIn,
        }}>
          {this.props.children}
        </AuthContext.Provider>
      </>
    );
  }
}

export function withAuth(WrappedComponent: any) {
  return class extends React.Component<{}> {
    render() {
      return (
        <AuthContext.Consumer>
          {(AuthController: AuthController) =>
            <WrappedComponent {...this.props} AuthController={AuthController}/>
          }
        </AuthContext.Consumer>
      );
    }
  };
}

export function useAuth(): AuthController {
  return React.useContext<AuthController>(AuthContext);
}
