import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { userService } from '../../services/Entity/UserService';
import styles from './Login.module.css';

export function Signup() {
  const { register, handleSubmit, formState : { errors } } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuth();

  async function onValidForm(data: any) {
    console.log('got data', data);
    setLoading(true);
    try {
      const loginResult = await userService.signupWithEmail({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password
      })
      auth.login(loginResult);
    } catch (err) {
      console.error("couldn't login", err);
    }
    setLoading(false);
  }

  function onErrorForm(data: any) {
    console.log('form error', data);
  }

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h2>Signup</h2>
        <form className={styles.form} onSubmit={handleSubmit(onValidForm, onErrorForm)}>
          <div>
            <input {...register('firstName', { required : true })} placeholder={'First Name'}/>
            <input {...register('lastName')} placeholder={'Last Name'}/>
            <input {...register('email', { required : true })}  placeholder={'E-mail'}/>
            <input {...register('password')} type={'password'} placeholder={'Password'}/>
            {errors.email && <span className={styles.error}>Please, enter icon-search.svg credentials.</span>}
          </div>
          <button type='submit' disabled={loading}>{loading ? 'Loading...' : 'Sign Up'}</button>
        </form>
        <hr/>
        Have and account? <Link to={'/login'}>Login</Link>
      </div>
    </div>
  );
}
