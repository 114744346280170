import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { positionService } from '../../../services/Entity/PositionService';
import styles from '../../Auth/Login.module.css';
import { ModalClose, ModalContent, ModalTitle } from '../../UI/Modal/Modal';

import css from './ModalAddPosition.module.css'

type Props = {
  onSuccess: () => void
  onClose: () => void
}

export const ModalAddPosition = (props: Props) => {
  const { register, handleSubmit, formState : { errors } } = useForm();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>();

  async function onValidForm(data: any) {
    setLoading(true);
    setError(undefined);
    try {
      await positionService.create({
        ...data,
        entryPrice : parseFloat(data.entryPrice),
      }, null);
      props.onSuccess();
    } catch (err: any) {
      setError(err.toString());
    }
    setLoading(false);
  }

  return (
    <ModalContent style={{ width : 200 }}>
      <ModalTitle>Add Position</ModalTitle>
      <ModalClose onClick={props.onClose}/>
      <form className={styles.form} onSubmit={handleSubmit(onValidForm)}>
        <input {...register('symbol', { required : true })} placeholder="Symbol"/>
        <input {...register('entryPrice', { required : true })} placeholder="Entry Price"/>
        {!!error && <div>{error}</div>}
        <button className={css.button} type="submit"
                disabled={loading}>{loading ? 'Loading...' : 'Add Position'}</button>
      </form>
    </ModalContent>
  );
};
