import cn from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { closestParent } from '../../../helpers/DOMHelper';
import { Icon } from '../Icon/Icon';

import css from './Modal.module.css';

type Props = {
  closeOnOutsideClick?: boolean
  onClose?: () => void
  children?: any
}

export function ModalContent({ children, className, style }: any) {
  return <div className={cn(css.content, className)} style={style}>{children}</div>;
}

export function ModalClose({ onClick }: any) {
  return <button className={css.close} onClick={onClick}><Icon name='close'/></button>;
}

export function ModalTitle({ onClick, children }: any) {
  return <h3 className={css.title} onClick={onClick}>{children}</h3>;
}

class Modal extends React.Component<Props, { opened: boolean }> {

  id = 'a' + Date.now();

  constructor(props: Props) {
    super(props);
    this.state = {
      opened : false,
    };
  }

  componentWillUnmount(): void {
    if (this.state.opened) {
      this.close();
    }
  }

  handleClose = (e: any) => {
    if (!closestParent(e.target, `#${this.id} .modal-content`)) {
      this.close();
    }
  };

  open = () => {
    this.setState({ opened : true });
    document.body.style.overflow = 'hidden';
    if (this.props.closeOnOutsideClick === false) {
      document.addEventListener('click', this.handleClose);
    }
  };

  close = () => {
    const { onClose } = this.props;
    const modalHolder = document.querySelector(`#${this.id}`);
    if (modalHolder) {
      modalHolder.classList.add(css.closing)
    }
    return new Promise<void>(resolve => {
      setTimeout(() => {
        this.setState({ opened : false });
        document.body.style.overflow = '';
        document.removeEventListener('click', this.handleClose);
        resolve();
        onClose && onClose();
      }, 300);
    })
  };

  render() {

    const { children } = this.props;
    const { opened } = this.state;

    return opened && ReactDOM.createPortal(
      <div className={css.modal} id={this.id}>
        <div className={css.inner}>
          {React.cloneElement(children, { ...children.props, closeModal : this.close })}
        </div>
      </div>,
      document.body,
    );
  }
}

export default Modal;
