import { DateTime } from 'luxon';
import React, { useContext, useEffect, useRef } from 'react';
import { Formatter } from '../../helpers/Formatter';
import { usePromise } from '../../hooks/usePromise';
import { HotSymbolContext } from '../../providers/HotSymbolContext';
import { positionService } from '../../services/Entity/PositionService';
import Modal from '../UI/Modal/Modal';
import { ModalAddPosition } from './ModalAddPosition/ModalAddPosition';
import css from './Positions.module.css';

export function Positions() {
  const addPositionModalRef = useRef<Modal>();
  const { data, loading, error, fetchData } = usePromise<any, any>(positionService.getAll.bind(positionService), {});
  const { setSymbol } = useContext(HotSymbolContext);

  function addPosition() {
    addPositionModalRef.current!.open();
  }

  function onPositionAdded() {
    fetchData();
    addPositionModalRef.current!.close();
  }

  function onAddPositionModalClose() {
    addPositionModalRef.current!.close();
  }

  function onSymbolClick(value: string) {
    setSymbol(value);
  }
  let prevSymbol = ''

  if (data?.edges?.length) {
    setTimeout(() => {
      setSymbol(data.edges[0].node?.symbol);
    }, 100)
  }

  return (
    <div>
      <h3 style={{ width : 570 }}>My Positions <span style={{ cursor : 'pointer' }} onClick={addPosition}>&#43;</span></h3>
      <table style={{ fontWeight : 500 }}>
        <thead>
          <tr>
            <td style={{ width : 50 }}>Sym<span style={{ marginLeft : 2 }}>&#9652;</span></td>
            <td style={{ width : 70 }}>Details</td>
            <td style={{ width : 50 }}>Size</td>
            <td style={{ width : 100 }}>Opened</td>
            <td style={{ width : 100 }}>Cost Basis</td>
            <td style={{ width : 100 }}>Last</td>
            <td style={{ width : 100 }}>Total</td>
          </tr>
        </thead>
        <tbody>
          {/* @todo fix table colors, use data below to see the diff */}
          {/*<tr style={{ verticalAlign : 'top' }}>*/}
          {/*  <td rowSpan={2}>GME</td>*/}
          {/*  <td>Stock</td>*/}
          {/*  <td>100</td>*/}
          {/*  <td>2/4/22</td>*/}
          {/*  <td>$140.00</td>*/}
          {/*  <td><span className={css.neg}>$126.20</span></td>*/}
          {/*  <td><span className={css.negSign}>-$1,380.00</span></td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>$130 Call</td>*/}
          {/*  <td>1 Sell</td>*/}
          {/*  <td>2/8/22</td>*/}
          {/*  <td>$12.50</td>*/}
          {/*  <td><span className={css.negSign}>-$5.50</span></td>*/}
          {/*  <td><span className={css.posSign}>+$1,250.00</span></td>*/}
          {/*</tr>*/}
          {loading && (
            <tr>
              <td colSpan={10000}>Loading...</td>
            </tr>
          )}
          {error && (
            <tr>
              <td colSpan={10000}>{error?.toString() || 'unexpected error'}</td>
            </tr>
          )}
          {data?.edges.map(({ node: item }: any) => {
            const isSameSymbol = item.symbol === prevSymbol;
            if (!isSameSymbol) {
              prevSymbol = item.symbol;
            }
            return (
              <tr key={item.id} style={{ verticalAlign : 'top' }}>
                <td rowSpan={isSameSymbol ? 2 : undefined} onClick={() => onSymbolClick(item.symbol)}>{!isSameSymbol && item.symbol}</td>
                <td>{item.type?.toUpperCase()}</td>
                <td>{item.size?.toFixed(0)}</td>
                <td>{item.openedAt ? DateTime.fromMillis(item.openedAt).toFormat('MM/d/yyyy') : '-'}</td>
                <td>{Formatter.amount(item.entryPrice)}</td>
                <td>
                  <span className={item.currentPrice < item.entryPrice ? css.neg : css.pos}>{Formatter.amount(item.currentPrice)}</span>
                </td>
                <td>
                  <span className={item.currentPrice < item.entryPrice ? css.negSign : css.posSign}>
                    {Formatter.amount(item.total)}
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* @ts-ignore */}
      <Modal ref={addPositionModalRef}>
        <ModalAddPosition onSuccess={onPositionAdded} onClose={onAddPositionModalClose} />
      </Modal>
    </div>
  );
}
