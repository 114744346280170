import { DocumentNode } from 'graphql';
import { graphqlService as gqlService } from './GraphqlService';

abstract class BaseEntityService {
  protected createMutation: DocumentNode | undefined;
  protected updateMutation: DocumentNode | undefined;
  protected deleteMutation: DocumentNode | undefined;

  protected itemQuery: DocumentNode | undefined;
  protected listQuery: DocumentNode | undefined;

  protected graphqlService = gqlService;

  create(data: any, extra: any = {}) {
    if (!this.createMutation) {
      throw new Error('Create mutation is not provided!');
    }

    return this.graphqlService.mutate({
      mutation: this.createMutation!,
      variables: { data, ...extra },
    });
  }

  update(id: string, data: any) {
    if (!this.updateMutation) {
      throw new Error('Update mutation is not provided!');
    }

    const variables = { id, data };
    return this.graphqlService.mutate({ mutation: this.updateMutation, variables });
  }

  delete(ids: string[]) {
    if (!this.deleteMutation) {
      throw new Error('Delete mutation is not provided!');
    }

    return this.graphqlService.mutate({ mutation: this.deleteMutation!, variables: { ids } });
  }

  getById<Result, Variables>(id: string): Promise<Result> {
    if (!this.itemQuery) {
      throw new Error('getById query is not provided!');
    }

    return this.graphqlService.query({
      query: this.itemQuery!,
      fetchPolicy: 'network-only',
      variables: { id },
    });
  }

  getAll<Result, Variables>(variables: Variables): Promise<Result> {
    if (!this.listQuery) {
      throw new Error('getAll query is not provided!');
    }

    return this.graphqlService.query({
      query: this.listQuery!,
      fetchPolicy: 'network-only',
      variables,
    });
  }
}

export default BaseEntityService;
