import { createContext, useContext, useState } from 'react';

export const HotSymbolContext = createContext({
  symbol    : null,
  setSymbol : () => {
  },
} as any);

export function HotSymbolProvider(props: any) {
  const [symbol, setSymbol] = useState(null);
  const value = {
    symbol,
    setSymbol,
  }
  return <HotSymbolContext.Provider value={value}>{props.children}</HotSymbolContext.Provider>
}

export function useHotSymbol() {
  return useContext(HotSymbolContext);
}
