class StorageService {

  prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  getItem = (key: string): string | null => localStorage.getItem(this.prefix + key);
  setItem = (key: string, value: string): void => localStorage.setItem(this.prefix + key, value);
  removeItem = (key: string): void => localStorage.removeItem(this.prefix + key);
  setJsonItem = (key: string, value: Object): void => localStorage.setItem(this.prefix + key, JSON.stringify(value));
  getJsonItem = (key: string): Object => {
    let data = localStorage.getItem(this.prefix + key);
    return data ? JSON.parse(data) : {};
  };
}

export default new StorageService('tradeassistio_');
