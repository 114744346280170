import { mutations } from '../../graphql/mutations';
import { queries } from '../../graphql/queries';
import BaseEntityService from './BaseEntityService';

type LoginResult = {
  __typename: 'TwoFactorRequest' | 'LoginData',
  accessToken: string, refreshToken: string, user: any, expiresIn: any,
  type: string,
  identifier: string,
};

class UserService extends BaseEntityService {
  listQuery = queries.users;
  createMutation = mutations.createUser;
  updateMutation = mutations.updateUser;
  deleteMutation = mutations.deleteUsers;

  async fetchMe(): Promise<any> {
    return this.graphqlService.query({ query: queries.me });
  }

  async updateMyProfile(data: any): Promise<any> {
    return this.graphqlService.mutate<any, any>({
      mutation: mutations.updateMyProfile,
      variables: {
        data,
      },
    });
  }

  async signupWithEmail(data: any): Promise<LoginResult> {
    return this.graphqlService.mutate({
      mutation: mutations.signupWithEmail,
      variables: {
        data
      },
    });
  }

  async loginWithEmail(data: any): Promise<LoginResult> {
    return this.graphqlService.mutate({
      mutation: mutations.loginWithEmail,
      variables: {
        data: {
          email: data.email,
          password: data.password,
        },
      },
    });

    //return new Promise((resolve) => {
    //  setTimeout(() => {
    //    resolve({
    //      __typename: 'LoginData',
    //      accessToken: 'access_token',
    //      refreshToken: 'refresh_token',
    //      user: {
    //        firstName: 'Alex',
    //        lastName: 'Viniychuk',
    //
    //      },
    //      expiresIn: null,
    //      type: '',
    //      identifier: '',
    //    })
    //  }, 1000)
    //})
  }

  async process2FA(hash: string, code: string): Promise<LoginResult> {
    return this.graphqlService.mutate({
      mutation: mutations.process2FA,
      variables: {
        code,
        hash,
      },
    });
  }

  logout() {

  }

}

export const userService = new UserService();
