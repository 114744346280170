import cn from 'classnames';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { closestParent } from '../../../helpers/DOMHelper';
import Button from '../Button/Button';

import css from './Dropdown.module.css';

type DropdownLabel = React.ReactNode | string;

const DROPDOWN_WIDTH = 200;
const DROPDOWN_BORDER_WIDTH = 4;

type Props = {
  clearable?: boolean
  align?: 'left' | 'right'
  label?: DropdownLabel
  style?: React.CSSProperties
  children?: any
}

export function Dropdown(props: Props) {
  const [opened, setOpened] = useState(false);
  const [id] = useState('a' + nanoid());
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleOutsideClick(e: MouseEvent) {
      const dropdown = closestParent(e.target, `#${id}`);
      if (!dropdown) {
        setOpened(false);
      } else {
        setOpened(!opened);
      }
    }

    document.addEventListener('click', handleOutsideClick);

    return function cleanup() {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const dropdownStyle = {
    top  : 0,
    left : 0,
  };

  if (opened) {
    const buttonRect = buttonRef.current!.getBoundingClientRect();
    const verticalOffset = 0;
    dropdownStyle.top = Math.round(buttonRect.top + buttonRect.height + verticalOffset) + window.scrollY;
    if (props.align === 'left') {
      dropdownStyle.left = buttonRect.left + window.scrollX;
    } else {
      dropdownStyle.left = buttonRect.right - DROPDOWN_WIDTH - DROPDOWN_BORDER_WIDTH + window.scrollX;
    }
  }

  return (
    <div className={css.dropdown} id={id} style={props.style}>
      <div ref={buttonRef} className={css.button}>
        {typeof props.label === 'string' ? <Button>{props.label}</Button> : props.label}
      </div>
      {opened && ReactDOM.createPortal(
        <div style={dropdownStyle} className={cn(css.menu, { '-opened' : opened })}>
          {props.children}
        </div>
        , document.body)}
    </div>
  );
}

type ItemProps = {
  children?: any
  onClick?: (e: any) => void
}

Dropdown.Item = (props: ItemProps) => <div className={css.item} onClick={props.onClick}>{props.children}</div>
