import * as React from 'react';
import cn from 'classnames';
import { camelize } from 'inflected';
import { CSSProperties } from 'react';
import { ReactComponent as IconSearch } from './icon-search.svg';
import { ReactComponent as IconSpinner } from './icon-spinner.svg';
import { ReactComponent as IconLogout } from './icon-logout.svg';
import { ReactComponent as IconClose } from './icon-close.svg';

const iconMap: any = {
  IconSearch,
  IconSpinner,
  IconLogout,
  IconClose,
};

type Props = {
  name: string
  className?: string
  size?: number
  style?: CSSProperties
}

export const Icon = (props: Props) => {
  const componentName = props.name.split('-').map(str => camelize(str)).join('');
  const IconComponent = iconMap['Icon' + componentName];
  let style: any = { ...props.style };
  if (props.size) {
    style.width = props.size;
    style.height = props.size;
  }

  return (
    <span className={cn('icon', 'icon-' + props.name, props.className)} style={style} title={props.name}>
      <IconComponent style={style} />
    </span>
  );
};
