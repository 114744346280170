import React, { createContext, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { Login } from '../Auth/Login';
import { Signup } from '../Auth/Signup';
import { ROUTE } from '../constants';
import { Dashboard } from '../Dashboard/Dashboard';

import './App.css';
import { Header } from '../Header/Header';
import { HotSymbolProvider } from '../../providers/HotSymbolContext';


function App() {
  const auth = useAuth();
  return (
    <>
      {auth.isLoggedIn ? (
        <HotSymbolProvider>
          <div className="App">
            <Header/>
            <Routes>
              <Route path={ROUTE.dashboard} element={<Dashboard/>}/>
              <Route
                path="*"
                element={<Navigate to={ROUTE.dashboard}/>}
              />
            </Routes>
          </div>
        </HotSymbolProvider>
      ) : (
        <Routes>
          <Route path={ROUTE.login} element={<Login/>}/>
          <Route path={ROUTE.signup} element={<Signup/>}/>
          <Route
            path="*"
            element={<Navigate to={ROUTE.login}/>}
          />
        </Routes>
      )}
    </>
  );
}

export default App;
