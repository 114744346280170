import React from 'react';

export const useDebouncedEffect = (fn: () => any, delay: number, deps: any[]) => {
  const callback = React.useCallback(fn, deps);
  React.useEffect(() => {
    const id = setTimeout(callback, delay);
    return () => {
      clearTimeout(id);
    };
  }, [callback, delay]);
};
